import { useStoryblok } from "@storyblok/react";
import Breadcrumb from "../../Components/Core/BreadCrumb";
import Banner from "../../Assets/Images/Banners/WhatToDoifYouHaveanAccident-banner.png"
import ContentHeaderOnly from "../../Components/ContentHeaderOnly";
import ImageList from "../../Components/ImageList";
import ListObject from "../../Components/ListObject";
import CardGrid from "../../Components/Cards/CardGrid";
import ButtonGroup from "../../Components/Buttons/ButtonGroup";
import Banners from "../../Components/Core/Banners";
import { Link } from "react-router-dom";
import { MDBSpinner } from 'mdb-react-ui-kit';

const items = [
    { to: '/', label: 'Home' },{ to: '/Customers', label: 'Customers' },
    { to: '/ClaimCenter', label: 'Claim Center' },{ to: '/Accident', label: 'What To Do If Involved In a Accident' }
]
const InvolvedAccident = () => {

    const storyblokVersion = process.env.REACT_APP_STORYBLOK_VERSION;

    const AccidentSlug= "lonestar/claims-content/checklist-of-actions-header"
    const AccidentStory = useStoryblok(AccidentSlug, { version: storyblokVersion });
    const ImgListSlug = "lonestar/claims-content/checklist-of-actions-imagelist";
    const ImgListStory = useStoryblok(ImgListSlug,{ version: storyblokVersion });
    const listSlug = "lonestar/claims-content/checklist-of-actions"
    const listStory = useStoryblok(listSlug,{ version: storyblokVersion });
    const thingsRememberSlug = "lonestar/claims-content/other-things-to-remember-in-the-event-of-a-loss"
    const thingRememberStory = useStoryblok(thingsRememberSlug,{version:storyblokVersion});
    const BtnSlug = "lonestar/claims-content/ready-to-file-a-claim";
    const BtnStory = useStoryblok(BtnSlug, {version: storyblokVersion});


    if(!AccidentStory?.content || !ImgListStory?.content || !listStory?.content
        || !thingRememberStory?.content || !BtnStory?.content) {
        return <div className="loader-section">
                    <MDBSpinner color='danger' className="spinner"></MDBSpinner>
                </div>
    }
    return ( <>
               
                <Banners img={Banner} mobileImg={Banner} category={"Claim Center"} page={"What to Do if You Have an Accident"} />
                <div style={{minHeight:"600px",backgroundColor:"#f0f0f0",paddingBottom:"2%"}}> 
                    <div className="mobile-adjust-center crumb-position pb-3 mobile-spacing-left">
                        <Breadcrumb>{
                            items.map(({to, label}) =>(           
                                <Link key={to} to={to}>{label}</Link>
                            ))}
                        </Breadcrumb>
                    </div>
                        <div className="col-lg-12 col-md-12 py-4 pb-1">
                            <ContentHeaderOnly data={AccidentStory.content} headingPosition="center"/>
                        </div>
                        <div className="row offset-lg-1 justify-content-center">
                            <div className="col-lg-2 ">
                                    <ImageList data={ImgListStory.content} />
                            </div>
                            <div className="col-lg-6 accident-mobile-section">
                                    <ListObject data ={listStory.content} />
                            </div>
                            
                        </div>
                </div>
                <div className="px-5 pb-4" style={{backgroundColor:"#DCDCDC",paddingTop:"2%"}}> 
                        <CardGrid data={thingRememberStory.content} />
                        <div className="row justify-content-center">
                            <div className="col-lg-5 pt-2" >
                                  <ButtonGroup data={[BtnStory.content]} />
                            </div>
                        </div>
                </div>

    </> );
}
 
export default InvolvedAccident 