import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import ExpandMenu from "./ExpandMenu";
import Logo from "../../Assets/Images/lonestarLogo.svg"
import X from "../../Assets/Images/grey-x.PNG";

const Drawer = (props) => {
  return (
    
    <>
      {props.isOpen && <Backdrop onClick={props.toggleDrawer} />}
        <SDrawer isOpen={props.isOpen}>
          <RightNav>
                  <div className="flex">
                      <a href="/" className="flex-start flex-basis-5"><img alt="icon" style={{ width: "150px" }} src={Logo}></img></a>
                      <div className="flex-end flex-basis-5" style={{ backgroundColor: "white", width: "25px", height: "25px" }}><img src={X} onClick={props.toggleDrawer} style={{ width: "25px", height: "25px", cursor: "pointer" }} alt="Grey X" /></div>
                  </div>
            <div>
              {props.routes.map((route,index) => {
                let StoryBlockView = route.page_visibility.sort();
                let CurrentView = props.view.sort();
                let isMatch = StoryBlockView.some((otherItem) =>  CurrentView.includes(otherItem));
                return( 
                  <>
                      {(isMatch) && (
                          route?.children_links != "" ? (
                             <ExpandMenu key={index} view={CurrentView} route={route} />
                          ): (<NavRoute onClick={props.toggleDrawer} key={index}  target={route.link_target} to={route.path}>{route.title}</NavRoute>)
                      )}
                  </>
                )
                })}
            </div>
          </RightNav>
        </SDrawer>
    </>
  );
};

export default Drawer;

const Backdrop = styled.div`
  min-height: 500vh;
  background-color:white !important;
  width: 100%;
  z-index: 10;
  position: absolute;
  top: 0;
  left: 0;
  transition: 0.3s ease;
  background-color: rgba(0, 0, 0, 0.2);
`;
const SDrawer = styled.div`
  z-index: 99999999999999999999 !important;
  position: absolute;
  top: 0;
  min-height: 100%;
  width: 100%;
  background-color: white;
  transition: 0.3s ease;
  transform: translateX(${(props) => (props.isOpen ? "0" : "-100%")});
`;
const RightNav = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 1rem;
`;
const NavRoute = styled(Link)`
  display: flex;
  text-decoration: none;
  color: black;
  font-size: 22px;
  font-family:'Poppins-Bold';
  padding: 0.5rem;
`;