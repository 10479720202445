import { useState, useEffect } from "react";
import Breadcrumb from "../Components/Core/BreadCrumb";
import { useStoryblok, StoryblokComponent } from "@storyblok/react";
import Banner from "../Assets/Images/Banners/commercial-auto_banner_1920x357.svg"
import MobileBanner from "../Assets/Images/Banners/commercial-auto_banner_1920x357.svg"
import { MDBSpinner } from 'mdb-react-ui-kit';
import { Link } from "react-router-dom";
const items = [
    { to: '/', label: 'Home' },
    { to: '/CommercialAuto', label: 'Commercial Auto Insurance' },
]

const CommercialAuto = () => {
    const [windowSize, setWindowSize] = useState(getWindowSize());
    
    const storyblokVersion = process.env.REACT_APP_STORYBLOK_VERSION;

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);
    const StoryAboutAuto = useStoryblok("lonestar/product-content/about-our-auto-insurance", { version: storyblokVersion });
    const StoryToSave = useStoryblok("lonestar/product-content/more-ways-to-save", { version: storyblokVersion });
    const StoryWaysToSave = useStoryblok("lonestar/product-content/list-of-more-ways-to-save", { version: storyblokVersion })
    const StoryDisclaimer = useStoryblok("lonestar/legal-content/products-disclaimer", { version: storyblokVersion });
    const PersonalAutoStory = useStoryblok("lonestar/product-content/personal-auto-quoting", { version: storyblokVersion });
    const CommercialAutoStory = useStoryblok("lonestar/pages/commercial-auto", { version: storyblokVersion });
    const iconOnly = useStoryblok("lonestar/producer/ca-icon", { version: storyblokVersion });

    if (!iconOnly?.content || !CommercialAutoStory?.content || !StoryAboutAuto?.content || !StoryToSave?.content || !StoryWaysToSave?.content
        || !StoryDisclaimer?.content || !PersonalAutoStory?.content) {
        return <div className="loader-section">
            <MDBSpinner color='danger' className="spinner"></MDBSpinner>
        </div>
    }

    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }
    const BannerSection = {
        background: `url(${Banner}) no-repeat center bottom`,
        height: "350px",
        backgroundSize: "cover",
        width: "100%"
    };
    const MobileBannerSection = {
        background: `url(${MobileBanner}) no-repeat center bottom`,
        height: "350px",
        backgroundSize: "cover",
        width: "100%"
    }
    return (
        <>
            <div className="flex-center">
                <div style={windowSize.innerWidth < 550 ? (MobileBannerSection) : (BannerSection)} className={windowSize.innerWidth < 550 ? ("header-section-mobile") : ("header-section")} >
                    <div className="flex align-items-top justify-content-center  mr-auto align-content-center">
                        <div className="justify-content-center pa-btn-text-pos">
                            <div className='banner-sub-heading'>
                                Your Journey, Our Care
                            </div>
                            <div className='banner-heading'>
                                Commercial Auto Insurance
                            </div>
                        </div>
                    </div>
                    {windowSize.innerWidth < 550 ? (
                        <>
                            <div className="flex-center justify-content-center">
                                <div className=" pa-banner-btn-section-mobile  align-content-center block justify-content-start align-items-center">
                                    <div className="product-banner gap-1">
                                        <img src={iconOnly.content.ICON.filename} className="product-banner-icon" />
                                        <div style={{ fontFamily: "Poppins-Bold", fontSize: "1rem" }}>Free Personal Auto Quote</div>
                                    </div>
                                    <div className="flex-center gap-1 wrap mb-4 justify-content-center">
                                        {CommercialAutoStory.content.body.map((nestedBlok) => {
                                            return nestedBlok?.buttons?.map((item, index) => {
                                                return item.link_target === "_self" ?
                                                    <div className={(item.button_style === "primary-lg-pill-btn" || item.button_style === "primary-sm-pill-btn") ? ("primary-sm-pill-btn-long") :
                                                        ((item.button_style === "white-lg-pill-btn") || (item.button_style === "white-sm-pill-btn")) && ("white-sm-pill-btn-long")}>
                                                        <Link to={`${item.link_url}`} >{item.button_text}</Link>
                                                    </div>
                                                    : <div className={(item.button_style === "primary-lg-pill-btn" || item.button_style === "primary-sm-pill-btn") ? ("primary-sm-pill-btn-long") :
                                                        ((item.button_style === "white-lg-pill-btn") || (item.button_style === "white-sm-pill-btn")) && ("white-sm-pill-btn-long")}><a style={{ fontFamily: "Poppins-Bold" }} href={`${item.link_url}`} target="_blank">{item.button_text}</a></div>
                                            })
                                        })}
                                    </div>
                                </div>

                            </div>
                        </>
                    ) : (<>
                        <div className="flex-center justify-content-center pl-2 pr-2">
                            <div className=" pa-banner-btn-section  align-content-center flex-center justify-content-start align-items-center">
                                <div className="flex-center gap-2 align-items-center">
                                    <img src={iconOnly.content.ICON.filename} className="product-banner-icon" />
                                    <div className="main-banner-text" style={{ maxWidth: "auto", minWidth: "fit-content" }}>Free Commercial Auto Quote</div>
                                    {CommercialAutoStory.content.body.map((nestedBlok) => {
                                        return nestedBlok?.buttons?.map((item, index) => {
                                            return item.link_target === "_self" ? <div className={item.button_style}><Link to={`${item.link_url}`} >{item.button_text}</Link></div>
                                                : <div className={item.button_style} style={{ maxWidth: "350px", minWidth: "fit-content", padding: "0px 15px" }}><a style={{ fontFamily: "Poppins-Bold" }} href={`${item.link_url}`} target="_blank">{item.button_text}</a></div>
                                        })
                                    })}
                                </div>
                            </div>

                        </div>
                    </>)}
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="mt-4 mx-4" style={{ width: "1410px" }}>
                    <Breadcrumb>{
                        items.map(({ to, label }) => (
                            <Link key={to} to={to}>{label}</Link>
                        ))}
                    </Breadcrumb>
                </div>
            </div>
            <div className="flex-center">
                <div className="mobile-p-2">
                    <StoryblokComponent blok={CommercialAutoStory.content} />
                </div>
            </div>
        </>
    )
}

export default CommercialAuto