import Banner from "../../Assets/Images/Banners/mailers_banner.png"
import Breadcrumb from "../../Components/Core/BreadCrumb";
import ButtonGroup from "../../Components/Buttons/ButtonGroup";
import Banners from "../../Components/Core/Banners";
import { Link } from 'react-router-dom';
import { useEffect, useState } from "react";

const items = [
    { to: '/ProducerPortal', label: 'Home' },
    { to: '/DocumentsAndPublications', label: 'Documents and Publications' },
    { to: '/Mailers', label: 'Mailers' }
]

const Mailers = () => {
    const [MailerStory,setMailerStory] = useState(null);
  
    const storyblokVersion = process.env.REACT_APP_STORYBLOK_VERSION;
   
    useEffect(() => {
        const fetchData = async () => {
            const data = await fetch(`https://api.storyblok.com/v2/cdn/stories/lonestar/producer/mailers?cv=1675403451&token=itfFFCQVQUKC7eI4jWAuHgtt&version=${storyblokVersion}`);
            const json = await data.json();

            setMailerStory(json)
        }
          fetchData()
    
    },[]);

    return ( 
        MailerStory&& (
            <>
                <Banners img={Banner} mobileImg={Banner} page={"Mailers"} category={"Documents and Publications"} />
                <div style={{minHeight:"800px",backgroundColor:"#f0f0f0",paddingBottom:"45px"}}> 
                    <div className="flex-center p-3">
                            <div className="page-start-text">
                                    <div className="flex-basis-10 py-4">
                                    <Breadcrumb >
                                        {items.map(({to, label}) => (
                                        <Link key={to} to={to}>{label}</Link>
                                        ))}
                                    </Breadcrumb>
                                    </div>
                                    <div className="flex-basis-10">
                                        <ButtonGroup data={[MailerStory.story.content]} />  
                                    </div>
                            </div>
                    </div>
            </div>
            </>
        )
       
    )
}
export default Mailers;