import { useState, useRef, useEffect } from "react";
import Banners from "../../Components/Core/Banners";
import { useParams,Link } from "react-router-dom";
import $ from "jquery";
import Banner from "../../Assets/Images/Banners/change-password_banner.svg";
import MobileBanner from "../../Assets/Images/Banners/mobile/changepw_390x282.svg";
import Breadcrumb from "../../Components/Core/BreadCrumb";
import { MDBSpinner } from 'mdb-react-ui-kit';
import PasswordResetModal from '../../Components/Forms/LoginUsers/Modals/PasswordResetModal'
import validate from '../../Components/Forms/Validation/LoginValidate';
import Bullet from '../../Assets/Images/blue-bullet_11x11.svg';
import { useAuth } from '../../utils/AuthProvider';
const items = [
    { to: '/', label: 'Home' },
    { to: '/ProducerLogin', label: 'Producer Login' },
    { to: '/ForgotPassword', label: 'Forgot Password?'},
    { to: '/ResetPassword', label: 'Change Password' }
]
const ResetPassword = () => {
    const PasswordRef = useRef("");
    const [passwords, setPasswords] = useState({
        Password: "",
        confirmPassword: "",
    });
    const [AllowPasswordChange, setAllowedPasswordChange] = useState("");
    const [formErrors, setFormErrors] = useState(1);
    const [Attempt, setAttempt] = useState(false);
    const [show, setShow] = useState(false);
    const [Submitting, setSubmitting] = useState(false);
    const auth = useAuth();
    const { token } = useParams();

    useEffect(() => {
        $.ajax({
            type: "POST",
            url: "/AuthenticateUser/ExecuteForgotPassword",
            data: JSON.stringify({
                PublicId: token
            }),
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            success: function (response) {
                setAllowedPasswordChange(response);
            },
            failure: function (response) {
                console.log(response);
            },
        });
    }, [token])

    const handleInputChange = (event) => {
        setPasswords({ ...passwords, [event.target.name]: event.target.value });
    };

    const handleShowPassword = (e) => {
        const passwordInput = document.querySelector("#Password")
        const eye = document.querySelector("#eye")
        eye.classList.toggle("fa-eye-slash")
        const type = passwordInput.getAttribute("type") === "password" ? "text" : "password"
        passwordInput.setAttribute("type", type)
    }
    const handleShowConfirmPassword = (e) => {
        const passwordInput = document.querySelector("#confirmPassword")
        const eye = document.querySelector("#eye-2")
        eye.classList.toggle("fa-eye-slash")
        const type = passwordInput.getAttribute("type") === "password" ? "text" : "password"
        passwordInput.setAttribute("type", type)
    }

    useEffect(() => {
        
        if (Submitting && formErrors === "Ready") {
            setShow(true);
            $.ajax({
                type: "POST",
                url: "/AuthenticateUser/RequiredPasswordChange",
                data: JSON.stringify({
                    UserId: AllowPasswordChange,
                    NewPassword: passwords.Password
                }),
                contentType: "application/json; charset=utf-8",
                dataType: "json"

            });
        }
    })
    useEffect(() => {
        if (Attempt === true) {

            setFormErrors(validate({
                Password:passwords.Password,
                Confirm:passwords.confirmPassword
            }))
            if (formErrors > 1) {
                setSubmitting(false);
            } else if (formErrors <= 0) {
                setFormErrors("Ready");
                setAttempt(false);
            }
        }
    })
    const updatePassword = (e) => {
        e.preventDefault();
        setAttempt(true);
        setSubmitting(true);

    }
    return (
        <>
            <Banners img={Banner} mobileImg={MobileBanner} page={"Producers"} category={"Change Password"} />
            <div className='flex-center wrap' style={{backgroundColor:"#f0f0f0"}}>
                <div className="pb-4 position-breadcrumb pt-3 login-breadcrumb">
                    <Breadcrumb>{
                        items.map(({ to, label }) => (
                            <Link key={to} to={to}>{label}</Link>
                        ))}
                    </Breadcrumb>
                </div>
            </div>
            <div className="flex-center pb-5" style={{backgroundColor:"#f0f0f0"}}>
                {
                    AllowPasswordChange !== "" || AllowPasswordChange !== -1 ? (
                        <>
                            <div className="md-form-body">
                                <div className="flex-center ">
                                    <label className="header-login">Change Password</label>
                                </div><br />
                                <form autoComplete="off">
                                    <div className="mb-2">New Password</div>
                                    <div className="password-container ">
                                        <input type="password" id="Password" maxLength="256" name="Password" ref={PasswordRef} value={passwords.Password} onChange={handleInputChange} className="form-input ResetPasswords" />
                                        <i className="fa-solid fa-eye" onClick={(e) => handleShowPassword(e)} id="eye"></i>
                                    </div>
                                    <br />
                                    <div className="mb-2">Confirm New Password</div>
                                    <div className="password-container">
                                        <input type="password" id="confirmPassword" name="confirmPassword" value={passwords.confirmPassword} onChange={handleInputChange} className="form-input ConfirmResetPassword" />
                                        <i className="fa-solid fa-eye " onClick={(e) => handleShowConfirmPassword(e)} id="eye-2"></i>
                                    </div>
                                    <br />
                                    <div className="white-smoke-background" style={{ marginTop: "2%", borderRadius: "10px", padding: "2% 10%" }}>
                                        <div style={{ fontSize: "1rem", color: "#B22727", fontFamily: "Poppins-Semi-Bold",paddingBottom:"2.5%" }}>Password Must:</div>
                                        <div style={{ paddingLeft: "5%", color: "#333333", fontFamily: "Poppins-Regular" }}>
                                            <div className="blue-bullet"><span><img src={Bullet} alt="Blue Bullet point" style={{ paddingRight: "3px" }} /></span> Be between 8 and 20 characters</div>
                                            <div className="blue-bullet"><span><img src={Bullet} alt="Blue Bullet point" style={{paddingRight:"3px"}} /></span> Include at least one number</div>
                                            <div className="blue-bullet"><span><img src={Bullet} alt="Blue Bullet point" style={{ paddingRight: "3px" }} /></span> Include at least one upper and lower case letter</div>
                                        </div>
                                    </div><br/>
                                    <div className="flex-center">
                                        <button className=" primary-lg-pill-btn" type="button" onClick={updatePassword}>Update Password</button>
                                    </div>
                                    <div className="flex-center">
                                        <Link className="flex-center" to="/ProducerLogin">Cancel</Link>
                                    </div>
                                </form>
                            </div>
                        </>
                    ): (
                         <>
                                 <div className="loader-section">
                                    <MDBSpinner color='danger' className="spinner"></MDBSpinner>
                                </div>
                        </>
                    )
                }
            </div>
            {
                show && (<PasswordResetModal setShow={setShow} show={show} />)
            }
        </>
    );
}
export default ResetPassword;