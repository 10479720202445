
import React from "react";
import Breadcrumb from '../Components/Core/BreadCrumb';
import Banner from "../Assets/Images/ClaimsFAQbanner.png"
import Banners from "../Components/Core/Banners";
import FAQ from "../Components/FAQ";
import { Link } from "react-router-dom";
import MobileBanner from "../Assets/Images/Banners/mobile/faq-banner_390x282.png";

const PaymentFAQ = () => {
   
    const items = [
        { to: '/', label: 'Home' },
        { to: '/Customers', label: 'Customers' },
        { to: '/MakeaPayment', label: 'Make A Payment' },
        { to: '/PaymentFAQ', label: 'Make a Payment FAQ'}
    ]
    return (
    <>  
         <Banners img={Banner} mobileImg={MobileBanner} page={"Make a Payment FAQ"} category={"Lonestar MGA"} />
         <div style={{minHeight:"600px", backgroundColor:"#F0F0F0"}}> 
                <div className="mobile-adjust-center crumb-position mobile-spacing-left pb-3">
                            <Breadcrumb>{
                                        items.map(({to, label}) =>(           
                                            <Link key={to} to={to}>{label}</Link>
                                        ))}
                             </Breadcrumb>
                </div>
                <div className="mobile-adjust-center" style={{display:"flex",justifyContent:"center",alignContent:"center",alignItems:"center"}}>
                    <div className="lg-w">
                            <FAQ page={"Payment"} />
                    </div>
                </div>
            </div>

    </> 
  
    )
        
}
 
export default PaymentFAQ 