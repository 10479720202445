import { useState, useEffect } from 'react'
import { MDBSpinner } from 'mdb-react-ui-kit';
import Banner from '../../Assets/Images/Banners/online-training-bann.png';
import MobileBanner from '../../Assets/Images/Banners/mobile/online-video_390x282.svg';
import Breadcrumb from '../../Components/Core/BreadCrumb';
import Banners from '../../Components/Core/Banners';
import { useStoryblok,StoryblokComponent } from '@storyblok/react';
import { Link } from 'react-router-dom';

const items = [
    {to:'/ProducerPortal',label:'Home'},
    {to:'/OnlineTraining',label:'Online Training'}
]
const OnlineTraining = () => {
    const [windowSize, setWindowSize] = useState(getWindowSize());
    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);
    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }
    const storyblokVersion = process.env.REACT_APP_STORYBLOK_VERSION;
    const story = useStoryblok("lonestar/producer/online-training", {version:storyblokVersion});
    if (!story?.content) {
        return <div className="loader-section">
                    <MDBSpinner color='danger' className="spinner"></MDBSpinner>
                </div>
    }
    return ( 
            <>
                <Banners img={Banner} mobileImg={MobileBanner} page={"Online Training"} category={"Lonestar MGA"} />      
                <div className='white-background'> 
                <div className='flex-center'>
                    <div className={windowSize.innerWidth < 850 ? 'standard-page-width flex-start px-4' : 'standard-page-width flex-start px-3 py-4'}>
                            <Breadcrumb>{
                                items.map(({to, label}) =>(           
                                    <Link key={to} to={to}>{label}</Link>
                                ))}
                            </Breadcrumb>
                        </div>
                    </div>
                    <div>
                        <StoryblokComponent blok={story.content} />
                    </div>
                </div>
                
            </>
     );
} 
 
export default OnlineTraining;