import styled from "styled-components";
import { useNavigate } from 'react-router-dom';
import GreenSuccessImg from "../../../../Assets/Images/updated_70x78.svg";
import Modal from 'react-bootstrap/Modal';

const SentModal = (props) => {
    const navigate = useNavigate();
  
    const handleClose = (e) => {
        e.preventDefault();
        props.setShow(false);
        navigate('/ProducerLogin');
    };
    return (
        <>
            <Modal
                aria-labelledby="contained-modal-title-vcenter"
                centered dialogClassName="max-W6 border-radius-2"
                style={{ maxWidth: "" }}
                show={props.show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}><br />
                <Header className='flex-center py-4 '>Password Successfully Updated!</Header>
                <div className="flex-center"><img alt ="Green circle with check mark icon" src={GreenSuccessImg} /></div>
                <div className="row justify-content-center py-4">
                    <div className='pb-2 pl-2'>
                        <input type="button" className="primary-sm-pill-btn" onClick={(e) => handleClose(e)} value={`Close`} />
                    </div>
                </div><br />
            </Modal>
        </>
    )
};
export default SentModal;
const Header = styled.div`
    font-size:1.5625rem;
    font-family:'Poppins-Bold';
    line-height: 2.1875rem;
    text-align:center;
`;
