import Banner from "../Assets/Images/Banners/make-a-payment-banner_1920x357.png"
import Breadcrumb from "../Components/Core/BreadCrumb";
import { useStoryblok } from "@storyblok/react";
import CardGrid from "../Components/Cards/CardGrid";
import Banners from "../Components/Core/Banners";
import MobileBanner from "../Assets/Images/Banners/mobile/make-a-payment-banner_390x282.png"
import { Link } from "react-router-dom";
import { MDBSpinner } from 'mdb-react-ui-kit';

const items = [
    { to: '/', label: 'Home' },
    { to: '/Customers', label: 'Customers' },
    { to: '/MakeaPayment', label: 'Make a Payment' }
]
const MakeaPayment = () => {

    const storyblokVersion = process.env.REACT_APP_STORYBLOK_VERSION;
    const Story = useStoryblok("lonestar/customer-content/other-ways-to-make-a-payment", { version: storyblokVersion });
    const PaymentStory = useStoryblok("lonestar/customer-content/make-a-payment-actions", { version: storyblokVersion });

    if (!Story.content || !PaymentStory.content) {
        return <div className="loader-section">
            <MDBSpinner color='danger' className="spinner"></MDBSpinner>
        </div>
    }
    return (
        <>
            <Banners page="Make a Payment" category="Services" img={Banner} mobileImg={MobileBanner} />
            <div style={{ minHeight: "1200px", backgroundColor: "#F0F0F0" }}>
                <div className="py-4 mobile-adjust-center crumb-position pl-5">
                    <Breadcrumb >{
                        items.map(({ to, label }) => (
                            <Link key={to} to={to}>{label}</Link>
                        ))}
                    </Breadcrumb>
                </div>
                <div className="flex-center">
                    <div className="make-a-payment-section">
                        <div className="flex-center">
                            <div className="p-4" >
                                <CardGrid data={PaymentStory.content} />
                            </div>
                        </div>
                        <div className="flex-center wrap white-body-2">
                            <CardGrid data={Story.content} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MakeaPayment 