import styled from "styled-components";
import SuccessImg from "../../../Assets/Images/send-mailform_412x339.png"
import { Link } from "react-router-dom";
const SuccessRequest = (props) => {
    window.scrollTo(0, 0) 
    return ( <>
           <SuccessBody className={`${props?.pos} `}>
           <div className="row justify-content-center"><div className="contactTitle" >
                {props.title}</div>
            </div>
            <div className= "row  justify-content-center" >
                    <img alt="Success Img" style={{width:"50%"}} src={SuccessImg}/>
            </div>
                <SuccessBodyText className="" >
                Your form has been sent and is being reviewed by one of our representatives. Someone will reach out to you as soon as possible by one of your preferred contacts phone or email.
                 </SuccessBodyText>
                 <div className="justify-content-center align-items-center row col-lg-12 pt-4 ">
                        <Link to="/ProducerPortal" className="large-SuccessBtn"> Go Back Home </Link>
                </div>
           </SuccessBody>
        </> 
    );
}
 
export default SuccessRequest;
const SuccessBodyText = styled.div`
    text-align:center;
    font-family:"Poppins-Regular";
    font-size:18px;
    line-height:35px;
    padding:0% 10% ;
    @media only screen and (max-width:800px){
        padding:5%;
        font-size:16px;
        line-height:25px;
    }
`;
const SuccessBody = styled.div`
    padding:10%;
    min-height: 737px;
    border-radius: 40px;
    background-color: #FFFFFF;
    box-shadow: 0 10px 20px 0 rgba(0,0,0,0.23);
    @media only screen and (max-width:800px){
        min-height: 537px;
        margin:0% 2% ;
        padding:10%;
    }
`;
