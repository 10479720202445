
import {useEffect,useState} from "react";
import { useStoryblok } from "@storyblok/react";
import { Link } from 'react-router-dom';
import Breadcrumb from "../Components/Core/BreadCrumb";
import FAQ from "../Components/FAQ";
import ContentHeaderOnly from "../Components/ContentHeaderOnly";
import Banners from "../Components/Core/Banners";
import MobileBanner from "../Assets/Images/Banners/mobile/contact-us_390x282.png";
import Banner from "../Assets/Images/Banners/contact-us-banner_1920x357.png"
import MasterContactUs from "../Components/Forms/Contact/MasterContactUs";
import { MDBSpinner } from 'mdb-react-ui-kit';
import ContentList from "../Components/ContentList";
import ContentItemText from "../Components/Storyblok/ContentItemText";

const items = [{ to: '/', label: 'Home' },{ to: '/ContactUs', label: ' Contact Us' },]

const ContactUs = () => {
    const [PageInquiry, setPageInquiry] = useState("1");
    const storyblokVersion = process.env.REACT_APP_STORYBLOK_VERSION;

    const faqStory = useStoryblok("lonestar/contact-us-faq",{ version: storyblokVersion });
    const sideBarStory = useStoryblok("lonestar/contact-us-sidebar", { version: storyblokVersion });
    const story = useStoryblok("lonestar/pages/contact-us", { version: storyblokVersion });
    const contactItemStory = useStoryblok("lonestar/business-hours/phone-number-hub", { version: storyblokVersion });
    const contentTextStory = useStoryblok("lonestar/business-hours/mail-us-hub", { version: storyblokVersion });

    if (!faqStory?.content || !sideBarStory?.content || !story?.content || !contactItemStory?.content || !contentTextStory?.content) {
        return <div className="loader-section">
                    <MDBSpinner color='danger' className="spinner"></MDBSpinner>
                </div>
    }

    const handleInquiryButtons = (e) => {
        e.preventDefault();
        const { value } = e.target;
        setPageInquiry(value);
    }
    return ( 
            <>
            <Banners img={Banner} mobileImg={MobileBanner} page={"Contact Us"} category={"Lonestar MGA"} />
            <div className="contact-page-body">
                <div style={{width:"1300px"} }>                 
                <div className="row justify-content-center p-4">
                        <div className="col-lg-11">
                            <Breadcrumb >
                                {items.map(({to, label}) => (
                                    <Link key={to} to={to}>{label}</Link>
                                ))}
                            </Breadcrumb>
                        </div>
                    </div>
                    <div className="row justify-content-center px-4">
                        <div className="col-lg-11">
                            <div className="white-blue-border-box">
                                <div className="text">
                                    We love questions and feedback - and we’re always happy to help! Here are some ways to contact us.
                                </div>
                            </div>
                        </div>
                    </div>
                    <br/>
                <div className="row contact-inquiry-buttons justify-content-center px-1">
                    <div className="row justify-content-center col-lg-11 gap-1">
                        <button className={PageInquiry === "0" ? "large-option-button active-option-button col-3" : "large-option-button col-3"} onClick={(e) => handleInquiryButtons(e)} value="0">Call Us</button>
                        <button className={PageInquiry === "1" ? "large-option-button active-option-button col-3" : "large-option-button col-3" } onClick={(e) => handleInquiryButtons(e)} value="1">Contact Us Form</button>
                        <button className={PageInquiry === "2" ? "large-option-button active-option-button col-3" : "large-option-button col-3"} onClick={(e) => handleInquiryButtons(e)} value="2">Mail Us</button>
                    </div>
                </div><br/>
                <div className="row justify-content-center px-4">
                    <div className="col-lg-11">
                    {   PageInquiry === "0" ?
                        (
                            <ContentList data={contactItemStory.content} />
                        ) : PageInquiry === "1" ?
                        (
                            <MasterContactUs />
                        ) : (
                            <ContentItemText data={contentTextStory.content } />
                        )
                    }
                    </div>
                </div>
                <br />
            </div>
            </div>
                <div className="section-faq">
                    <ContentHeaderOnly data={faqStory.content} headingPosition="center" />
                    <FAQ page={"Contact"}/>
                </div>
            </> 
    )
}
export default ContactUs;