import { useState, useEffect } from "react";
import Breadcrumb from "../Components/Core/BreadCrumb";
import { useStoryblok } from "@storyblok/react";
import Banner from "../Assets/Images/Banners/autoinsurance_overlay.png"
import MobileBanner from "../Assets/Images/Banners/mobile/autoinsurance-banner__390x282.png"
import RedCar from "../Assets/Images/car-red_43x35.svg"
import MediaObject from "../Components/MediaObject";
import ListObject from "../Components/ListObject";
import { MDBSpinner } from 'mdb-react-ui-kit';
import { Link } from "react-router-dom";
import TextSection from "../Components/TextSection";
const items = [
    { to: '/', label: 'Home' }, { to: '/PersonalAuto', label: 'Personal Auto Insurance' },
]

const PersonalAuto = () => {
    const [windowSize, setWindowSize] = useState(getWindowSize());

    const storyblokVersion = process.env.REACT_APP_STORYBLOK_VERSION;

    const BannerSection = {
        background: `url(${Banner}) no-repeat center bottom`,
        height: "350px",
        backgroundSize: "cover",
        width: "100%"
    };
    const MobileBannerSection = {
        background: `url(${MobileBanner}) no-repeat center bottom`,
        height: "350px",
        backgroundSize: "cover",
        width: "100%"
    }
    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);
    const StoryAboutAuto = useStoryblok("lonestar/product-content/about-our-auto-insurance", { version: storyblokVersion });
    const StoryToSave = useStoryblok("lonestar/product-content/more-ways-to-save", { version: storyblokVersion });
    const StoryWaysToSave = useStoryblok("lonestar/product-content/list-of-more-ways-to-save", { version: storyblokVersion })
    const StoryDisclaimer = useStoryblok("lonestar/legal-content/products-disclaimer", { version: storyblokVersion });
    const PersonalAutoStory = useStoryblok("lonestar/product-content/personal-auto-quoting", { version: storyblokVersion });

    if (!StoryAboutAuto?.content || !StoryToSave?.content || !StoryWaysToSave?.content || !StoryDisclaimer?.content || !PersonalAutoStory?.content) {
        return <div className="loader-section">
            <MDBSpinner color='danger' className="spinner"></MDBSpinner>
        </div>
    }
    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }

    return (
        <>
            <div style={{ justifyContent: "center", display: "flex" }}>
                <div style={windowSize.innerWidth < 550 ? (MobileBannerSection) : (BannerSection)} className={windowSize.innerWidth < 550 ? ("header-section-mobile") : ("header-section")} >
                    <div className="flex align-items-top justify-content-center  mr-auto align-content-center">
                        <div className="justify-content-center pa-btn-text-pos">
                            <div className='banner-sub-heading'>
                                Your Journey, Our Care
                            </div>
                            <div className='banner-heading'>
                                Personal Auto Insurance Product
                            </div>
                        </div>
                    </div>
                    {windowSize.innerWidth < 550 ? (
                        <>
                            <div className="flex-center justify-content-center">
                                <div className=" pa-banner-btn-section-mobile  align-content-center block justify-content-start align-items-center">
                                    <div className="flex-center pt-3 align-content-center align-items-center gap-1">
                                        <img src={RedCar} style={{ maxHeight: "64px", width: "64px" }} />
                                        <div style={{ fontFamily: "Poppins-Bold", fontSize: "1rem" }}>Free Personal Auto Quote</div>
                                    </div>
                                    <div className="flex-center gap-1 wrap mb-4 justify-content-center">
                                        {PersonalAutoStory.content.buttons.map((item) => {
                                            return item.link_target === "_self" ?
                                                <div className={(item.button_style === "primary-lg-pill-btn" || item.button_style === "primary-sm-pill-btn") ? ("primary-sm-pill-btn-long") :
                                                    ((item.button_style === "white-lg-pill-btn") || (item.button_style === "white-sm-pill-btn")) && ("white-sm-pill-btn-long")}>
                                                    <Link to={`${item.link_url}`} >{item.button_text}</Link>
                                                </div>
                                                : <div className={(item.button_style === "primary-lg-pill-btn" || item.button_style === "primary-sm-pill-btn") ? ("primary-sm-pill-btn-long") :
                                                    ((item.button_style === "white-lg-pill-btn") || (item.button_style === "white-sm-pill-btn")) && ("white-sm-pill-btn-long")}><a style={{ fontFamily: "Poppins-Bold" }} href={`${item.link_url}`} target="_blank">{item.button_text}</a></div>
                                        })}
                                    </div>
                                </div>

                            </div>
                        </>
                    ) : (<>
                        <div className="flex-center justify-content-center pl-2 pr-2">
                            <div className=" pa-banner-btn-section  align-content-center flex-center justify-content-start align-items-center">
                                <div className="flex-center gap-2">
                                    <img src={RedCar} style={{ maxHeight: "64px", width: "64px" }} />
                                    <div className="main-banner-text">Free Personal Auto Quote</div>
                                    {PersonalAutoStory.content.buttons.map((item) => {
                                        return item.link_target === "_self" ? <div className={item.button_style}><Link to={`${item.link_url}`} >{item.button_text}</Link></div>
                                            : <div className={item.button_style}><a style={{ fontFamily: "Poppins-Bold" }} href={`${item.link_url}`} target="_blank">{item.button_text}</a></div>
                                    })}
                                </div>
                            </div>

                        </div>
                    </>)}

                </div>
            </div>
            <div className="p-3 pb-4">
                <div className="flex-center pb-3">
                    <div className="page-start-text" style={{ width: "1300px" }}>
                        <div className="flex-basis-10 py-2">
                            <Breadcrumb>{
                                items.map(({ to, label }) => (
                                    <Link key={to} to={to}>{label}</Link>
                                ))}
                            </Breadcrumb>
                        </div>
                    </div>
                </div>
                <div className="flex-center">
                    <div className="personal-auto-mo-section">
                        <MediaObject data={StoryAboutAuto.content} />
                    </div>
                </div>
                <br />
            </div>
            <div className="section-9 flex-center wrap align-items-start justify-content-center">
                <div className="flex-basis-3">
                    <MediaObject data={StoryToSave.content} />
                </div>
                <div className="flex-basis-5 mobile-flex-10">
                    <ListObject data={StoryWaysToSave.content} />
                </div>
            </div>
            <div className="white-smoke-background">
                <TextSection data={StoryDisclaimer?.content} />
            </div>
        </>
    )
}
export default PersonalAuto