import React from "react";
import Banners from "../../Components/Core/Banners";
import "../../Assets/Style/breadcrumb.scss";
import Banner from "../../Assets/Images/Banners/file-a-claim-banner_1920x357.png";
import MasterFileClaimForm from "../../Components/Forms/FileClaim/MasterFileClaimForm";
import Breadcrumb from "../../Components/Core/BreadCrumb";
import { Link } from "react-router-dom";
import { QAOnly } from "../../Components/Storyblok/QAOnly";
const items = [
    { to: "/", label: "Home" },
    { to: "/Customers", label: "Customers" },
    { to: "/ClaimCenter", label: "Claim Center" },
    { to: "/FileClaim", label: "File a Claim" },
];
const storyblokVersion = process.env.REACT_APP_STORYBLOK_VERSION;
const FileClaim = () => {
    return (
        <>
            <Banners
                img={Banner}
                mobileImg={Banner}
                page={"File A Claim Form"}
                category={"Lonestar MGA"}
            />
            <div style={{ minHeight: "1500px", backgroundColor: "#F0F0F0" }}>
                <div className="flex-center pb-3">
                    <div className="page-start-text" style={{ width: "1100px" }}>
                        <div className="flex-basis-10 py-4">
                            <Breadcrumb>
                                {items.map(({ to, label }) => (
                                    <Link key={to} to={to}>
                                        {label}
                                    </Link>
                                ))}
                            </Breadcrumb>
                        </div>
                    </div>
                </div>
                {storyblokVersion === "draft" &&
                    <QAOnly/>
                }
                <div className=" flex-center wrap">
                    <div className="form-section-claim">
                        <MasterFileClaimForm />
                    </div>
                </div>
            </div>
        </>
    );
};
export default FileClaim;
