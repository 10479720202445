import Banners from "../../Components/Core/Banners";
import Banner from "../../Assets/Images/ClaimsFAQbanner.png"
import Breadcrumb from "../../Components/Core/BreadCrumb";
import MobileBanner from "../../Assets/Images/Banners/mobile/faq-banner_390x282.png"
import FAQ from "../../Components/FAQ";
import { Link } from "react-router-dom";
    const items = [
        { to: '/', label: 'Home' },
        { to: '/Customers', label: 'Customers' },
        { to: '/ClaimCenter', label: 'Claim Center' },
        { to: '/ClaimFAQ', label: 'Claims FAQ'}
    ]
const ClaimFAQ = () => {
    
    return ( <>
        <Banners img={Banner} mobileImg={MobileBanner} page={"Claims FAQ"} category={"Lonestar MGA"} />
        <div style={{minHeight:"600px", backgroundColor:"#F0F0F0"}}> 
                <div className="mobile-adjust-center crumb-position pb-3 mobile-spacing-left">
                    <Breadcrumb>{
                        items.map(({to, label}) =>(           
                            <Link key={to} to={to}>{label}</Link>
                        ))}
                    </Breadcrumb>
                </div>
                <div className="mobile-adjust-center " style={{display:"flex",justifyContent:"center",alignContent:"center",alignItems:"center"}}>
                    <div className="lg-w ">
                            <FAQ page={"Claims"} />
                    </div>
                </div>
            </div>
    </> );
}
 
export default ClaimFAQ;