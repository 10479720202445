import { useEffect, useState } from "react";
import { useStoryblok } from "@storyblok/react";
import { MDBSpinner } from 'mdb-react-ui-kit';
import styled from "styled-components";
import states from "../../../Data/US-states";
import validate from "../Validation/FormValidation"
import { limit,formatPhoneNumber } from "../../../Helpers/InputHelper";
import Package from "../../../Assets/Images/package_75x79.png"
import BookDefault from "../../../Assets/Images/guide-default_30x30.png";
import BookActive from "../../../Assets/Images/guide-active_30x30.png";
import BinderDefault from "../../../Assets/Images/binder-default_30x30.png";
import BinderActive from "../../../Assets/Images/binder-active_30x30.png";
import CardDefault from "../../../Assets/Images/card-default_30x30.png";
import CardActive from "../../../Assets/Images/card-active_30x30.png";
import $ from "jquery";


const OrderSuppliesForm = (props) => {
    const [FormErrors, setFormErrors] = useState(-1);
    const [IsSubmitting, setIsSubmitting] = useState(false);
    const [Attempt, setAttempt] = useState(false);
    const [PdfSB, setPdfSB] = useState(null);

    const storyblokVersion = process.env.REACT_APP_STORYBLOK_VERSION;
    const pdfStory = useStoryblok("lonestar/producer/forms/order-supply", { version: storyblokVersion });

    useEffect(() => {
        const loadedCheck = async () => {
            if (!pdfStory?.content) {
                return <div className="loader-section">
                    <MDBSpinner color='danger' className="spinner"></MDBSpinner>
                </div>
            } else {
                setPdfSB(pdfStory.content)
            }
        }

        loadedCheck();
    },[pdfStory]);
    useEffect(() => {

        if (FormErrors === 0 && IsSubmitting) {
            props.submitForm();
            window.scrollTo(0, 0)
            $.ajax({
                type: "POST",
                url: "/Email/SendSuppliesFormEmail",
                data: JSON.stringify(props.state, function (k, v) { return v === undefined ? "" : v; }),
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                failure: function (response) {
                    alert(response.d);
                }
            });
            setFormErrors(-1);
        }
    }, [FormErrors, IsSubmitting]);

        if (Attempt === true) {
            setFormErrors(validate(props.state))
            if (FormErrors > -1) {
                setIsSubmitting(false);
                let erroed = document.querySelectorAll("[style*='background-color: white; border: 3px solid rgb(178, 39, 39);']");
                erroed.forEach((elm, index) => {
                    elm.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'end' });
                })
            } else if (FormErrors <= 0) {
                setFormErrors("Ready");
                setAttempt(false);
            }
        }

    const handleSubmit = (e) => {
        e.preventDefault();
        setAttempt(!Attempt);
        setIsSubmitting(true);
        setFormErrors(validate(props.state))
        if (FormErrors === 0) {
            setFormErrors("Ready");
            setIsSubmitting(true);
            setAttempt(false);
        }
    };

    return (
        <>

            <div className=" py-4 flex-center">
                <InfoHeader className="flex-center">
                    <Icon src={Package}></Icon>
                    <TextSection className="col justify-content-center">
                        <HeaderTitle className="row">About Ordering Supplies</HeaderTitle>
                        <HeaderDescription className="row">Licensed producers should fill out the form below and select supplies. We will only ship out the materials to licensed producers and to the address you submit below. There is no cost to order items here.</HeaderDescription>
                    </TextSection>
                </InfoHeader>
            </div>
            <form noValidate autoComplete="off" className="flex-center  justify-content-center">
                <FormBody>
                    <FormSubHead className="flex-center">Lonestar MGA</FormSubHead>
                    <FormHeading className="row justify-content-center">Supply Order Form</FormHeading>
                    <Label >Shipping Address Information</Label>
                    <div className=" my-2 required-box">
                        <div className="required-txt required-asterisk">Required</div>
                    </div>
                    <br />
                    <div className="row">
                        <div className="col-lg-6 pb-4">First Name<span className="required-asterisk"></span> <br />
                            <input id="Yes" maxLength="256" className="pl-2 form-input" value={props.state.ContactFirstName} onChange={props.handleChange} type="text" name="ContactFirstName" /><br />
                        </div>
                        <div className="col-lg-6 pb-4">Last Name<span className="required-asterisk"></span> <br />
                            <input id="Yes" maxLength="256" className="pl-2 form-input" value={props.state.ContactLastName} onChange={props.handleChange} type="text" name="ContactLastName" /><br />
                        </div>
                    </div>
                    <div> Agency Name<span className="required-asterisk"></span>
                        <input id="Yes" maxLength="256" className="pl-2 form-input" value={props.state.AgencyName} onChange={props.handleChange} type="text" name="AgencyName" />
                    </div><br />
                    <div className="row">
                        <div className="col-lg-6 pb-4">Street<span className="required-asterisk"></span> <br />
                            <input id="Yes" maxLength="256" className="pl-2 form-input" value={props.state.Street} onChange={props.handleChange} type="text" name="Street" /><br />
                        </div>
                        <div className=" col-lg-6 pb-4">City<span className="required-asterisk"></span> <br />
                            <input id="Yes" maxLength="256" className="pl-2 form-input" value={props.state.City} onChange={props.handleChange} type="text" name="City" /><br />
                        </div>
                    </div>
                    <div className="row">
                        <div className=" col-lg-4 pb-4 "> State<span className="required-asterisk"></span>  <br />
                            <SelectStyle className="form-input pl-2" id="YesSelect" onChange={props.handleChange} name="State" >
                                {states.map(option => (
                                    <option key={option.index} id="YesSelect" value={option.label}>{option.label}</option>
                                ))}
                            </SelectStyle>
                        </div><br />
                        <div className="col-lg-2 pb-4"> ZIP Code<span className="required-asterisk"></span>  <br />
                            <FormInputSmall id="Yes" className="pl-2" value={props.state.Zip} onKeyUp={(e) => limit(e, 5)} onKeyDown={(e) => limit(e, 4)} onChange={props.handleChange} type="number" name="Zip" /><br />
                        </div>
                        <div className="col-lg-6 pb-4"> Phone Number<span className="required-asterisk"></span>  <br />
                            <FormInputSmall id="Yes" className="pl-2" value={props.state.Phone} onChange={props.handleChange} onKeyUp={(e) => formatPhoneNumber(e)} onKeyDown={(e) => formatPhoneNumber(e)} maxLength="12" type="tel" name="Phone" /><br />
                        </div>
                    </div>
                    <div> Email Address<span className="required-asterisk"></span>
                        <input className="form-input pl-2" maxLength="256" id="Yes" value={props.state.Email} onChange={props.handleChange} type="text" name="Email" />
                    </div><br />
                    <OrderList>
                        <Label className="col my-4">Ordering List</Label>
                        <div className=" m-2">
                            <div className="flex-center pl-2 mr-2">
                                <Category className="flex-basis-5 ml-4">Items</Category>
                                <Category style={{ textAlign: "center" }} className="flex-basis-4 justify-content-center align-items-center align-content-center">Quantity</Category>
                            </div>
                            <div className="divider" />
                            <div className=" py-4">
                                <div className="flex-start flex-basis-10 item-sec align-content-center wrap-mobile ">
                                    {((props.state.MaverickDesktopGuide === '') || (props.state.MaverickDesktopGuide === '0')) &&
                                        ((props.state.PointRiderDesktopGuide === '') || (props.state.PointRiderDesktopGuide === '0')) ? (
                                        <img className="Order-Icon" alt={"book grey"} src={BookDefault} />
                                    ) : (<img className="Order-Icon" alt={"book red"} src={BookActive} />)}
                                    <div className="pl-3 Order-Txt">Desktop Guide & Pay Plans</div>
                                    <div className="flex align-items-center order-pdf-mobile">
                                        <div className="order-pdf-sec-start"><a className={`order-pdf-btn`} target="_blank" href={PdfSB?.buttons[0]?.download_url} >View Maverick</a></div>
                                        <div className="ml-2"><a className={`order-pdf-btn`} target="_blank" href={PdfSB?.buttons[1]?.download_url}>View PointRider</a></div>
                                    </div>
                                </div>
                                <div className="flex flex-basis-10 item-sec">
                                    <div className="order-select-type">Select Type</div>
                                </div>
                                <div className="flex-center">
                                    <div className={`mt-2  ${(props.state.MaverickDesktopGuide === '') || (props.state.MaverickDesktopGuide === '0') ? (`form-default-pill`) : (`form-active-pill`)}`}>
                                        <div className="flex-basis-5 item">Maverick</div>
                                        <div className="flex-basis-5 flex justify-content-center align-items-center pl-4-desktop">
                                            <button className={` mr-2 ${(props.state.MaverickDesktopGuide === '') || (props.state.MaverickDesktopGuide === '0') ? (`minus`) : (`minus-active`)}`} name="MaverickDesktopGuide" value={props.state.MaverickDesktopGuide} onClick={(e) => props.handleAmountChange(e, "DECREASE")}></button><br />
                                            <input className="Order-Quantity" type="number" placeholder="0" onChange={props.handleChange} value={props.state.MaverickDesktopGuide} name="MaverickDesktopGuide" ></input>
                                            <button className={` ml-2 ${(props.state.MaverickDesktopGuide === '') || (props.state.MaverickDesktopGuide === '0') ? (`plus`) : (`plus-active`)}`} name="MaverickDesktopGuide" value={props.state.MaverickDesktopGuide} onClick={(e) => props.handleAmountChange(e, "INCREASE")}></button>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-center">
                                    <div className={`flex mt-2  ${(props.state.PointRiderDesktopGuide === '') || (props.state.PointRiderDesktopGuide === '0') ? (`form-default-pill`) : (`form-active-pill`)}`} >
                                        <div className="item flex-basis-5">Point Rider</div>
                                        <div className="flex-basis-5 flex justify-content-center align-items-center pl-4-desktop">
                                            <button className={` mr-2 ${(props.state.PointRiderDesktopGuide === '') || (props.state.PointRiderDesktopGuide === '0') ? (`minus`) : (`minus-active`)}`} name="PointRiderDesktopGuide" value={props.state.PointRiderDesktopGuide} onClick={(e) => props.handleAmountChange(e, "DECREASE")}></button><br />
                                            <input className="Order-Quantity" type="number" placeholder="0" onChange={props.handleChange} value={props.state.PointRiderDesktopGuide} name="PointRiderDesktopGuide" ></input>
                                            <button className={` ml-2 ${(props.state.PointRiderDesktopGuide === '') || (props.state.PointRiderDesktopGuide === '0') ? (`plus`) : (`plus-active`)}`} name="PointRiderDesktopGuide" value={props.state.PointRiderDesktopGuide} onClick={(e) => props.handleAmountChange(e, "INCREASE")}></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Split />
                            <div className="flex-start wrap-mobile  py-4">
                                <div className="flex-center flex-basis-6-full">
                                    {props.state.BinderEnvelopes === '' || props.state.BinderEnvelopes === '0' ? (
                                        <img className="Order-Icon" alt={"book default"} src={BinderDefault} />) : (
                                        <img className="Order-Icon" alt={"book active"} src={BinderActive} />)}
                                    <div className="pl-3 Order-Txt">Envelopes - Policy Information Enclosed</div>
                                    <div className="flex align-items-center order-pdf-mobile">
                                        <div className="order-pdf-sec-start"><a className={`order-pdf-btn`} target="_blank" href={PdfSB?.buttons[2]?.download_url} >View</a></div>
                                    </div>
                                </div>
                                <div className="flex justify-content-center align-items-center flex-basis-5-full " >
                                    <button className=" minus mr-2" name="BinderEnvelopes" value={props.state.BinderEnvelopes} onClick={(e) => props.handleAmountChange(e, "DECREASE")}></button><br />
                                    <input className="Order-Quantity" type="number" placeholder="0" onChange={props.handleChange} value={props.state.BinderEnvelopes} name="BinderEnvelopes" ></input>
                                    <button className=" plus ml-2" name="BinderEnvelopes" value={props.state.BinderEnvelopes} onClick={(e) => props.handleAmountChange(e, "INCREASE")}></button>
                                </div>
                            </div>
                            <Split />
                            <div className="flex align-items-center wrap-mobile py-4">
                                <div className="flex-start flex-basis-6-full item-sec">
                                    {props.state.ClaimCards === '' || props.state.ClaimCards === '0' ? (
                                        <img className="Order-Icon" alt={"book default"} src={CardDefault} />) : (
                                        <img className="Order-Icon" alt={"book active"} src={CardActive} />)}
                                    <div className="pl-2 Order-Txt">Claim Cards</div>
                                    <div className="flex align-items-center order-pdf-mobile">
                                        <div className="order-pdf-sec-start"><a className={`order-pdf-btn`} target="_blank" href={PdfSB?.buttons[3]?.download_url} >View</a></div>
                                    </div>
                                </div>
                                <div className="flex justify-content-center align-items-center flex-basis-5-full">
                                    <button className="minus mr-2" name="ClaimCards" value={props.state.ClaimCards} onClick={(e) => props.handleAmountChange(e, "DECREASE")}></button><br />
                                    <input className="Order-Quantity" type="number" placeholder="0" onChange={props.handleChange} value={props.state.ClaimCards} name="ClaimCards" ></input>
                                    <button className="plus ml-2" name="ClaimCards" value={props.state.ClaimCards} onClick={(e) => props.handleAmountChange(e, "INCREASE")}></button>
                                </div>
                            </div>
                            <Split />
                            <div className="flex align-items-center wrap-mobile py-4">
                                <div className="flex-start flex-basis-6-full item-sec">
                                    {props.state.PointRiderUnderWritingGuideline === '' || props.state.PointRiderUnderWritingGuideline === '0' ? (
                                        <img className="Order-Icon" alt={"book default"} src={BookDefault} />) : (
                                        <img className="Order-Icon" alt={"book active"} src={BookActive} />)}
                                    <div className="pl-2 Order-Txt">Underwriting Guidelines</div>
                                    <div className="flex align-items-center order-pdf-mobile">
                                        <div className="order-pdf-sec-start"><a className={`order-pdf-btn`} target="_blank" href={PdfSB?.buttons[4]?.download_url} >View PointRider</a></div>
                                    </div>
                                </div>
                                <div className="flex justify-content-center align-items-center flex-basis-5-full">
                                    <button className="minus mr-2" name="PointRiderUnderWritingGuideline" value={props.state.PointRiderUnderWritingGuideline} onClick={(e) => props.handleAmountChange(e, "DECREASE")}></button><br />
                                    <input className="Order-Quantity" type="number" placeholder="0" onChange={props.handleChange} value={props.state.PointRiderUnderWritingGuideline} name="PointRiderUnderWritingGuideline" ></input>
                                    <button className="plus ml-2" name="PointRiderUnderWritingGuideline" value={props.state.PointRiderUnderWritingGuideline} onClick={(e) => props.handleAmountChange(e, "INCREASE")}></button>
                                </div>
                            </div>
                        </div>
                    </OrderList><br />
                    <div className="row p-3">
                        <div className="pb-2">Comments</div>
                        <textarea className="text-area" name="Comment" maxLength="1024" value={props.state.Comment} onChange={props.handleChange} />
                    </div>
                    <br />
                    <button onClick={handleSubmit} className="primary-lg-pill-btn align-items-center text-center" > Submit Request </button> <br />
                </FormBody>
            </form>
        </>
    );
}
export default OrderSuppliesForm

const SelectStyle = styled.select`
:focus {
    border: 2px solid #004C78;
}
`;

const InfoHeader = styled.div`
    min-height: 146px;
    width: 100%;
    border-radius: 28px;
    background-color: #FFFFFF;
`;
const TextSection = styled.div`
    min-height: 98px;
    max-width: 888px;
    margin: 24px 5% 24px 0px;

`;
const Label = styled.div`
    min-height: 33px;
    color: #222222;
    font-family: Poppins;
    font-size: 120%;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 35px;
`;
const HeaderTitle = styled.div`
    min-height: 28px;
    color: #004C78;
    font-family: Poppins;
    font-size: 100%;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 30px;
`;
const HeaderDescription = styled.div`
    min-height: 70px;
    max-width: 888px;
    color: #222222;
    font-family: Poppins;
    font-size: 100%;
    letter-spacing: 0;
    line-height: 35px;
`;
const Icon = styled.img`
    max-height: 75px;
    max-width: 69px;
    margin: 30px 23px 35px 23px;
`;
const FormBody = styled.div`
    padding:5%;
    min-height: 1076px;
    width:100%;
    border-radius: 36px;
    background-color: #FFFFFF;
    box-shadow: 0 10px 20px 0 rgba(0,0,0,0.23);
    @media only screen and (max-width:800px){
        padding: 10% 10% 10% 10% !important;
    }
`;
const FormSubHead = styled.div`
    min-height: 31px;
    text-align:center;
    color: #B22727;
    font-family: Poppins;
    font-size: 110%;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 33px;
`;
const FormHeading = styled.div`
        color: #222222;
        font-family: Poppins;
        font-size: 2rem;
        letter-spacing: 0;
        line-height: 60px;  
        @media only screen and (max-width:800px){
            font-size: 20px;
            line-height: 40px;
            padding-bottom:5%; 
        }
    
`;
const FormInputSmall = styled.input`
        box-sizing: border-box;
        height: 46px;
        width:100%;
        border: 1px solid #808080;
        border-radius: 5px;
        background-color: #FFFFFF;
        outline: none;
        &:focus, &:active {
            border: 2px solid #004C78 !important;
        }
`;

const OrderList = styled.div`
    box-sizing: border-box;
    min-height: 523px;
    width:100%;
    border: 1px solid #DCDCDC;
    border-radius: 15px;
    background-color: #F0F0F0;
`;

const Split = styled.div`
    box-sizing: border-box;
    height: 1px;
    width: 100%;
    border: 1px solid #DCDCDC;
`;
const Category = styled.div`
    height: 35px;
    width: 47px;
    color: #B22727;
    font-family: Poppins;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 35px;
    
`;
