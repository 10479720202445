import AlertIcon from '../Assets/Images/alert-red.png'
import DateFormatter from './Core/DateFormatter'
import GroupAnnouncements from './Core/GroupAnnouncements';
import { FormattedCurrentDate,BoundCheck } from '../Helpers/InputHelper';

export default function Announcement(props) {
  
    let currentDate = FormattedCurrentDate();
    let groupAnnouncement = groupAnnouncements(props.data);

    
    function parseDateTime(data){
        const values = data.split(' ');
        return values;
    }

    function convertMonth(format){
        switch(format){
            case '01':return 'January';
            case '02': return 'February';
            case '03': return 'March';
            case '04': return 'April';
            case '05': return 'May';
            case '06': return 'June';
            case '07': return 'July';
            case '08': return 'August';
            case '09': return 'September';
            case '10': return 'October';
            case '11': return 'November';
            case '12': return 'December';
            default: return format;
        }
    }
    function groupAnnouncements(data){
        let startDate = [];
        let organized = [];
        let vals = [];
        data.map((item) => {
           return startDate.push({date:parseDateTime(item.content.start_date),announcement:item});
           
        })
        startDate.map((item)=> {
            vals = item.date[0].split('-');
            organized.push({year:vals[0],month:convertMonth(vals[1]), announcement:item.announcement})
        })
      return organized
    }
        return (
            <>{ props.data.length > 0 ? (
              
                <>  
                  { props.landing === "true" ? (
                    props.data.map((item) => {
                        let startDate = parseDateTime(item.content.start_date);
                        let endDate = parseDateTime(item.content.end_date);

                     return(
                        (BoundCheck(startDate, endDate,currentDate)) && (
                        (item.content.display_on_portal_homepage === true &&
                            <>
                                <div className={`flex-start announcement-landing`} >
                                    <img src={AlertIcon} alt="Red Bell Icon" className=' announcement-icon'></img>
                                    <div className=' pl-3 flex-center wrap'>
                                        <div className='announcement-title'>{item.content.alert_title}</div> 
                                        <div className='announcement-date'><DateFormatter startDate={item.content.start_date}/></div>
                                        <div className='announcement-text'>{item.content.alert_text}</div>
                                    </div>
                                </div>
                            
                            </>
                        )
                    ))
                })
            ): props.landing !== "true" && 
              (
                    <>
                        {        
                        props.data.map((item) => {
                            let startDate = parseDateTime(item.content.start_date);
                            let endDate = parseDateTime(item.content.end_date);
                            return(
                                (BoundCheck(startDate, endDate,currentDate)) && ( 
                                    item.content.display_on_portal_homepage === true && (
                                    <>
                                        <div className={`row main-announcement-body`} >
                                            <div >
                                            <div className='announcement-title main-title pb-2'>{item.content.alert_title}</div> 
                                            <div className='announcement-text main-text'>{item.content.alert_text}</div>
                                            </div>
                                        </div> <br/><br/>
                                    </>
                                )
                                ))
                        })
                    }
             
                    { 
                        <GroupAnnouncements data= {groupAnnouncement} />
                    }
                  </>
                ) }

    </>):(
            // <>
            //    <div className={`flex-start announcement-landing`} >
            //         <img src={AlertIcon} alt="Red Bell Icon" className=' announcement-icon'></img>
            //         <div className=' pl-3 flex-center wrap'>
            //             <div className='announcement-title'>Coming Soon</div> 
            //             <div className='announcement-date'></div>
            //             <div className='announcement-text'>Announcement </div>
            //         </div>
            //     </div>      
            // </>
            <>
            </>
        )}
           
    </>
  )
}


