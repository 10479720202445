import Banner from "../Assets/Images/Banners/privacynotice-banner_1920x357.png"
import Breadcrumb from "../Components/Core/BreadCrumb"
import { useStoryblok } from "@storyblok/react";
import TextSection from "../Components/TextSection";
import Banners from "../Components/Core/Banners";
import { MDBSpinner } from 'mdb-react-ui-kit';
import MobileBanner from "../Assets/Images/Banners/mobile/privacynotice-banner_390x282.png"
import { Link } from "react-router-dom";
const items = [
    { to: '/', label: 'Home' },{ to: '/PrivacyPolicy', label: 'Privacy Policy' },
]
const PrivacyNotice = () => {

    const storyblokVersion = process.env.REACT_APP_STORYBLOK_VERSION;


    const slug = "lonestar/legal-content/privacy-policy-1"
    const story = useStoryblok(slug, { version: storyblokVersion });

    const slug2 = "lonestar/legal-content/privacy-policy-2"
    const story2 = useStoryblok(slug2, { version: storyblokVersion });

    const slug3 = "lonestar/legal-content/privacy-policy-3"
    const story3 = useStoryblok(slug3, { version: storyblokVersion });

  
    if(!story?.content || !story2?.content || !story3?.content 
     ) {
        return <div className="loader-section">
                <MDBSpinner color='danger' className="spinner"></MDBSpinner>
                </div>
    }
    
    return ( <>
            <Banners img={Banner} mobileImg={MobileBanner} page={"Privacy Policy"} category={"Lonestar MGA"} />  
          
            <div style={ story.content.background_color === "default" ? {backgroundColor:"#DCDCDC"} : {backgroundColor:"#FFFFFF"} }> 
                    <div style={{maxWidth:"1238.66px"}} className="mobile-adjust-center crumb-position pb-3 mobile-spacing-left">
                        <Breadcrumb>{
                            items.map(({to, label}) =>(           
                                <Link to={to}>{label}</Link>
                            ))}
                        </Breadcrumb>
                    </div>
                   <div className="pb-4">
                    <TextSection data={story.content} />
                   </div>
            </div>
            <div className="flex-center align-items-center p-4" style={ story2.content.background_color === "default" ? {backgroundColor:"#DCDCDC"} : {backgroundColor:"#FFFFFF"} } > 
                <div >
                    <TextSection data={story2.content} />
                 </div>
            </div>

            <div className="flex-center align-items-center p-4" style={ story3.content.background_color === "default" ? {backgroundColor:"#DCDCDC"} : {backgroundColor:"#FFFFFF"} }> 
                  <div>
                   <TextSection data={story3.content} />
                  </div>
           </div>

    </> )
}
 
export default PrivacyNotice