import Banner from "../Assets/Images/Banners/terms-of-use-banner_1920x357.png"
import MobileBanner from "../Assets/Images/Banners/mobile/terms-of-use_390x282.png"
import Breadcrumb from "../Components/Core/BreadCrumb"
import { useStoryblok } from "@storyblok/react";
import TextSection from "../Components/TextSection";
import Banners from "../Components/Core/Banners";
import { MDBSpinner } from 'mdb-react-ui-kit';
import { Link } from "react-router-dom";
const items = [
    { to: '/', label: 'Home' },{ to: '/TermsofUse', label: 'Terms of Use' },
]

const TermsofUse = () => {
    const storyblokVersion = process.env.REACT_APP_STORYBLOK_VERSION;


    const slug = "lonestar/legal-content/terms-of-use"
    const story = useStoryblok(slug, { version: storyblokVersion });

    if(!story?.content){
        return <div className="loader-section">
                    <MDBSpinner color='danger' className="spinner"></MDBSpinner>
                </div>
    }
    return ( 
        <>
        <Banners img={Banner} mobileImg={MobileBanner} page={"Terms of Use"} category={"Lonestar MGA"} />
           
                <div style={{minHeight:"600px", backgroundColor:"#DCDCDC"}}> 
                    <div style={{maxWidth:"1238.66px"}}  className="mobile-adjust-center crumb-position pb-3 mobile-spacing-left">
                        <Breadcrumb>{
                            items.map(({to, label}) =>(           
                                <Link to={to}>{label}</Link>
                            ))}
                        </Breadcrumb>
                    </div>
                <div className="">
                    <TextSection data={story.content} />
                </div>
            </div>
        </> );
}
 
export default TermsofUse;