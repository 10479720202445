import React from "react";
import { MDBSpinner } from 'mdb-react-ui-kit';
import { useStoryblok } from "@storyblok/react";
import RichText from "../Core/RichText";
const storyblokVersion = process.env.REACT_APP_STORYBLOK_VERSION;

export const QAOnly = () => {
    let QAStory = useStoryblok("lonestar/qa-only/qa-banner", { version: storyblokVersion });
    if (!QAStory?.content) {
        return (
            <div className="loader-section">
                <MDBSpinner color="danger" className="spinner"></MDBSpinner>
            </div>
        );
    }
    let QAData = QAStory.content;
    return (
        <div className="row justify-content-center text-center">
            <div className="col-lg-7">
                <div className="white-red-border-box p-3">
                    <h4 className="col-lg-12 text-danger">
                        <b>{QAData.header[0].headline}</b>
                    </h4>
                    <div className="col-lg-12">
                        <RichText Description={QAData.header[0].description} />
                    </div>
                </div>
            </div>
        </div>
    );
};
