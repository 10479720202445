import Banners from "../../Components/Core/Banners";
import Banner from "../../Assets/Images/Banners/change-password_banner.svg"
import MobileBanner from '../../Assets/Images/Banners/mobile/changepw_390x282.svg'
import { Link } from "react-router-dom";
import Breadcrumb from "../../Components/Core/BreadCrumb";
import ChangePasswordForm from "../../Components/Forms/LoginUsers/ChangePasswordForm";
const items = [
    { to: '/', label: 'Home' },
    { to: '/ProducerPortal', label: 'Producers' },
    {to: '/ChangePassword', label: 'Change Password'}
]
const ChangePassword = () => {
    return ( 
            <>
                <Banners img={Banner} mobileImg={MobileBanner} page={"My Profile"} category={"Change Password"} />
                <div className='flex-center wrap white-smoke-background'>
                    <div className="pb-4 pt-3 login-breadcrumb pl-3">
                        <Breadcrumb>{
                            items.map(({to, label}) =>(           
                                    <Link key={to} to={to}>{label}</Link>
                            ))}
                        </Breadcrumb>
                    </div>
                </div>
            <div className="flex-center wrap change-password-page-body">
                    <div className="flex-basis-10 " style={{textAlign:"center"}}>
                        <h4>We Need You to Set a New Password</h4>
                    </div>
                    <div className="flex-basis-10 flex-center">
                        <div style={{fontSize:"1.125rem", padding:"0px 13px",maxWidth:"1300px"}}>
                            In order to protect your login account for Lonestar MGA, we are asking you to set a new password for your future sign-ins here.
                        </div>
                    </div><br/><br/>
                    <ChangePasswordForm />
                </div>
            </> 
    );
}
export default ChangePassword;