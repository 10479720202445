import styled from "styled-components";
import {useNavigate } from 'react-router-dom';

import Modal from 'react-bootstrap/Modal';
import EmailSent from "../../../../Assets/Images/email-sent_143x98.svg";
const SentModal = (props) => {

    const navigate = useNavigate(); 
    const handleClose = () => {
        props.setShow(false);
        navigate('/ProducerLogin');
    };
    return (
        <>
            <Modal
                aria-labelledby="contained-modal-title-vcenter"
                centered dialogClassName="max-W6 border-radius-2"
                style={{maxWidth:""}}
                show={props.show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}><br />
                <Header className='row py-4 justify-content-center'>Email has Been Sent</Header>
                <div className="flex-center"><img alt="Paper Air Plane with green check icon" src={EmailSent} /></div>
                <div className="px-5">
                    <Message className="flex-center">
                        Please check your email and click the link to change password.
                    </Message>
                </div>
                <div className="row justify-content-center py-4">
                    <div className='pb-2 pl-2'>
                        <input type="button" className="primary-sm-pill-btn" onClick={handleClose} value={`Close`} />
                    </div>
                </div><br />
            </Modal>
        </>
    )
};
export default SentModal;
const Header = styled.div`
    font-size:25px;
    font-family:'Poppins-Bold';
    line-height: 35px;
`;
const Message = styled.div`
    min-height: 70px;
    color: #222222;
    font-family: Poppins;
    font-size: 1.3rem;
    letter-spacing: 0;
    line-height: 35px;
    text-align: center;
`;