import Banner from "../../../Assets/Images/Banners/formsandguidelines_borderless.svg"
import Breadcrumb from "../../../Components/Core/BreadCrumb";
import { useStoryblok } from "@storyblok/react";
import ButtonGroup from "../../../Components/Buttons/ButtonGroup";
import Banners from "../../../Components/Core/Banners";
import MobileBanner from "../../../Assets/Images/Banners/mobile/forms-and-guidelines_389x275.svg"
import { useEffect, useState } from "react"

import { Link } from "react-router-dom";
import { MDBSpinner } from 'mdb-react-ui-kit';

const storyblokVersion = process.env.REACT_APP_STORYBLOK_VERSION;

const items = [
    { to:'/ProducerPortal', label:'Home'},
    { to: '/DocumentsAndPublications', label: 'Documents and Publications' },
    { to: '/FormsAndGuidelines', label: 'Forms and Guidelines' }
]

const FormsAndGuidelines = () => {
    const [PermissionGranted, setPermissionGranted] = useState(false);

    const guidelineStory = useStoryblok("lonestar/producer/texas-forms-and-guidelines",{ version: storyblokVersion });
    const formsCommercial = useStoryblok("lonestar/producer/texas-forms-and-guidelines2-0", { version: storyblokVersion });

    useEffect(() => {
        let producerId = JSON.parse(localStorage.getItem("DATA"))?.rtnval?.externalId;

        if (producerId === null) {
            setPermissionGranted(false);
            //console.log("in the if for undefned: producer portal external ID: ButtonSB.js");
        } else {
            setPermissionGranted(true);
            //console.log("in the if for YES: producer portal external ID: ButtonSB.js");
        }
    });

    if (!guidelineStory?.content || !formsCommercial?.content) {
        return<div className="loader-section">
                    <MDBSpinner color='danger' className="spinner"></MDBSpinner>
               </div>
    }

    return ( 
        <>
        <Banners img={Banner} mobileImg={MobileBanner} page={"Forms and Guidelines"} category={"Documents and Publications"} />      
            <div style={{minHeight:"800px",backgroundColor:"#f0f0f0",paddingBottom:"40px"}}> 
            <div style={{ justifyContent:"center",alignItems:"center",display:"inline"}}>
                    <div style={{maxWidth:"950px"}} className="mobile-adjust-center crumb-position pb-3">
                        <Breadcrumb>{
                            items.map(({ to, label }) => (
                                <Link key={to} to={to}>{label}</Link>
                            ))}
                        </Breadcrumb>
                    </div>
                    <div className="link-btn-sec py-2 " style={{fontSize:"18px", textAlign:"left"}}>
                        Select on the specific state product guidelines below to learn more.<br/>     
                    </div><br/>
                    <div className="link-btn-sec">
                            <ButtonGroup data={[guidelineStory.content]} />
                    </div><br />
                    { PermissionGranted &&
                        <div className="link-btn-sec">
                            <ButtonGroup data={[formsCommercial.content]} />
                        </div>
                    }
                </div>
            </div>
        </> 
    );
}
 
export default FormsAndGuidelines 