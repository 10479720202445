
import styled from "styled-components";
import CustomerIcon from "../../../Assets/Images/customer_50x50.png";
import IncidentIcon from "../../../Assets/Images/incidentwithcustomer_50x50.png";
import RepresentIcon from "../../../Assets/Images/anothercarrier_50x50.png"
import React,{useState,useEffect} from "react";
import validate from "../Validation/FormValidation";
import $ from "jquery";

const ClaimStatusForm = ({submitForm}) => {

const [formErrors, setFormErrors] = useState(1);
const [isSubmitting,setIsSubmitting] = useState(false);
const [Attempt,setAttempt] = useState(false);
const [FormValue, setFormValues] = useState ({
        UserType:'null',
        FirstName:'',
        LastName:'',
        PhoneNumber:'',
        PolicyClaimNumber:'',
        EmailAddress:'',
        Message:''
})
const customerStyle = {
    backgroundImage: `url(${CustomerIcon})`,
    backgroundSize:"51px",
    backgroundRepeat: "no-repeat",
    backgroundPosition:"5% 40%"
};
const incidentStyle = {
    backgroundImage: `url(${IncidentIcon})`,
    backgroundSize:"51px",
    backgroundRepeat: "no-repeat",
    backgroundPosition:"5% 40%"
};
const representStyle = {
    backgroundImage: `url(${RepresentIcon})`,
    backgroundSize:"51px",
    backgroundRepeat: "no-repeat",
    backgroundPosition:"5% 40%"
};

useEffect(() => {
    if (formErrors === 0 && isSubmitting === true) {
        submitForm();
        //console.log(JSON.stringify(FormValue, function (k, v) { return v === undefined ? "" : v; }))
        $.ajax({
            type: "POST",
            url: "/Email/SendClaimStatusFormEmail",
            data: JSON.stringify(FormValue, function (k, v) { return v === undefined ? "" : v; }),
            contentType: "application/json; charset=utf-8",
            dataType: "json",
            failure: function (response) {
                alert(response.d);
            }
        });
        window.scrollTo(0, 0) 
   }
 },[formErrors, isSubmitting]);
 useEffect(() => {
  
    if(Attempt === true){ 
            setFormErrors(validate(FormValue))
            if(formErrors > 1){
               setIsSubmitting(false)
            }
        }
})

let handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFormValues({
      ...FormValue,
      [name]: value
    })
}
const handleInput = (e) => {
    if (e.key !== 'Backspace' && (e.target.value.length === 3 || e.target.value.length === 7)){
        e.target.value += '-';
        }
}; 
 const handleSubmit = e => {
    e.preventDefault();
    setAttempt(!Attempt);
    setIsSubmitting(true); 
    
};
    return ( <>
                <form noValidate autoComplete="off" >
                    <FormBody className="container-flex">
                            <FormHeading className="mb-4 justify-content-center">Claim Status Request Form</FormHeading>
                            <Label>Person Requesting Claim Status</Label>
                            <div className="py-2 required-box">
                                    <div className="required-txt required-asterisk">Required</div>
                            </div>
                            <InputLabel className=" required-asterisk mb-2">Who is filling out this claim status request form?</InputLabel>
                            <input type="button"  className={`mb-3 user-type-btn ${(FormValue.UserType === "I'm a Lonestar customer") ? 'onBtn' : 'offBtn'}`} value="I'm a Lonestar customer" onClick={handleChange} name="UserType" style={customerStyle}></input>
                            <input type="button"  className={`mb-3  user-type-btn ${(FormValue.UserType === "I had an accident with a Lonestar customer") ? 'onBtn' : 'offBtn'}`} onClick={handleChange} name="UserType" value="I had an accident with a Lonestar customer" style={incidentStyle}></input>
                            <input type="button"  className={`mb-3 user-type-btn ${(FormValue.UserType === "I represent another insurance company") ? 'onBtn' : 'offBtn'}`} onClick={handleChange} name="UserType" value="I represent another insurance company" style={representStyle}></input>
                            <div className="flex-basis-10 flex-center gap-3  pt-3">
                                <div className ="flex-basis-5">
                                    <div className="required-asterisk mb-2">First Name</div>
                                    <input id="Yes" onChange={handleChange} className="form-input" value={FormValue.FirstName}  type="text" maxLength="256" name="FirstName"/>
                                </div>
                                <div className="flex-basis-5">
                                    <div className="required-asterisk mb-2">Last Name</div>
                                    <input id="Yes" onChange={handleChange} className="form-input" value={FormValue.LastName}  type="text" maxLength="256" name="LastName"/>
                                </div>
                            </div>
                            <div className="flex-center flex-basis-10 gap-3 pt-4">
                                <div className ="flex-basis-5">
                                    <div className="required-asterisk mb-2">Phone Number</div>
                        <input className="form-input" id="Yes" value={FormValue.PhoneNumber} onChange={handleChange} onKeyUp={(e) => handleInput(e)} onKeyDown={(e) => handleInput(e)} maxLength="12" type="tel" name="PhoneNumber"/>
                                </div>
                                <div className="flex-basis-5">
                                   <div className="required-asterisk mb-2">Policy/Claim #</div>
                                    <input className="form-input" id="Yes" value={FormValue.PolicyClaimNumber} onChange={handleChange} type="text" maxLength="256" name="PolicyClaimNumber"/>
                                </div>
                            </div>
                            <div className="required-asterisk  flex-basis-10 pt-4 mb-2"> Email Address</div>
                            <input className="form-input" id="Yes" onChange={handleChange}  value={FormValue.EmailAddress}  type="email" name="EmailAddress"/><br/>
                            <div className="flex-start wrap flex-basis-10">
                                <div className="flex-basis-10 pt-4">Comments</div>
                                <textarea className="mt-2 text-area flex-basis-10" name="Message" maxLength="1024" value={FormValue.Message} onChange={handleChange}/>
                            </div><br/><br/>
                            <div className="flex-basis-10 flex-center pt-4 ">
                                <button onClick={handleSubmit} className="primary-lg-pill-btn" style={{height:"73px"}}> Submit Request </button> <br/>
                            </div>
                    </FormBody>
                </form>
    </> );
}
 
export default ClaimStatusForm;

const InputLabel = styled.div`
        font-family: 'Poppins-Regular';
        width:100%;
        height:auto;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 20px;
        padding-top:2%;
`;

const Label = styled.div`
    min-height: 33px;
    color: #222222;
    font-family: Poppins;
    font-size: 120%;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 35px;
    flex-basis:100%;
`;

const FormHeading = styled.div`
    min-height: 51px;
    width: 100%;
    color: #000000;
    font-family: Poppins;
    font-size: 200%;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 55px;
    text-align: center;
    @media only screen and (min-width:1900px){
        font-size: 40px;
    }
    @media only screen and (max-width:800px){
        font-size: 125%;
        padding-top:5%;
        line-height:30px;
    }
px
`;
const FormBody = styled.div`
    min-height: 1076px;
    border-radius: 36px;
    background-color: #FFFFFF;
    box-shadow: 0 10px 20px 0 rgba(0,0,0,0.23);
    padding: 5% 10%;
`;